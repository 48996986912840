<template>
  <div id="enterpriseAnalysis">
    <div class="e-a-title">招商分析大屏</div>
    <div class="e-a-middle-wrap">
      <div class="e-a-middle-title">请选择行业领域，生成招商分析大屏</div>
      <el-button type="primary" @click="$router.push('/enterpriseField')" class="e-a-middle-btn">选择行业领域</el-button>
    </div>
    <div class="e-a-bottom">
      <div class="e-a-bottom-title">以下是您的历史选择，大数据实时更新大屏显示</div>
      <el-table class="discover-table" :data="historyCompanyData" stripe style="margin-top: 20px" v-loading="loading">
        <el-table-column align="center" label="行业领域">
          <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.industry" :key="index" style="margin: 0 10px">{{item.name}}</span>
          </template>          
        </el-table-column>
        <el-table-column align="center" label="创建时间" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.created_at.date | dateSet}}</span>
          </template>          
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="pcScreen(scope.row)" type="text" size="small">数据大屏展示</el-button>
            <!-- <el-button @click="wxScreen(scope.row)" type="text" size="small">小程序大屏</el-button> -->
            <el-button @click="Del(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagings">
        <span class="paging" @click="firstPage">首页</span>
        <span class="paging" @click="downUserPage">上一页</span>
        <input class="paging inputPage" type="number" v-model="userPage">
        <span class="paging" @click="addUserPage">下一页</span>
        <span class="paging" @click="lastPage">末页</span>
        <span class="paging">共{{user_last_page}}页</span>
    </div>
    <!-- <div class="e-a-bottom e-a-n-box">
      <div>展示设置</div>
      <div class="e-a-numSet">
        显示数值 = （ 原数值+ <el-input placeholder="请输入数字" v-model="addNum" class="e-a-innput"></el-input> ）x <el-input placeholder="请输入数字" v-model="mulNum" class="e-a-innput"
        ></el-input>
        <el-button @click="numSet" type="primary" class="e-a-numSet-btn">确定</el-button>
      </div>
    </div> -->
  </div>
</template>
<script>
  export default {
    name: "enterpriseAnalysis",
    data() {
      return {
        typeValue: 'month',
        addNum: 0,
        mulNum: 1,
        data_type: 'zst',
        organ_id: sessionStorage.organizationId,
        userPage: 1,
        user_last_page:1,
        page: 1,
        meta: {},
        historyCompanyData: [],
        loading: false
      }
    },
    watch: {
      userPage (val,oldVal){
        if(val <= 0){
          this.userPage = 1
        }else if(val > this.user_last_page){
          this.userPage = this.user_last_page
        }
        this.pubCompanyFun()        
      }
    },
    filters: {
      dateSet(d){
        return d.slice(0,10)
      }
    },
    methods: {
      // 用户列表上一页
      downUserPage(){
          if(this.userPage == 1){
              error('已经是第一页了')
          }else{
              let page = this.userPage;
              this.loading = true
              page--;
              this.userPage = page;
              this.pubCompanyFun();
          }
      },
      // 用户列表下一页
      addUserPage(){
          if(this.userPage == this.user_last_page){
              error('已经是最后一页了')
          }else{
              let page = this.userPage;
              this.loading = true
              page++;
              this.userPage = page;
              this.pubCompanyFun();
          }
      },
      // 首页
      firstPage(){
          this.userPage = 1;
          this.loading = true
          this.pubCompanyFun();
      },
      // 末页
      lastPage(){
          let page = this.user_last_page;
          this.userPage = page;
          this.loading = true
          this.pubCompanyFun();
      },
      // 跳转大屏
      pcScreen(row) {
        // console.log(row.id)
        this.$router.push({
          path: '/xiaoShanInvestment',
          query:{
            data_type: this.data_type,
            organ_id: this.organ_id,
            range_id: row.id
          }
        })
      },
      // 跳转wx
      wxScreen(row) {
        this.$router.push({
          path: '/xiaoShanInvestmentM',
          query: {
            jumpType:'tab',
            range_id: row.id
          }
        })
      },
      // 删除该行
      Del(row) {
        // console.log(row.id)
        this.$confirm('是否删除该条大屏历史?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          // this.$http.delete('http://my.webapi.weitianshi.com.cn/api/admin/screen/'+row.id+'/range').then(r => {
          this.$http.delete('https://wts.weitianshi.cn/api/admin/screen/'+row.id+'/range').then(r => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.loading = false            
            this.pubCompanyFun()
          }).catch(err => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          })          
        }).catch(() => {
        });
      },
      // 小程序端展示
      showScreen(row) {
        // console.log('展示',row)
        let obj = {
          data_type: this.data_type,
          organ_id: this.organ_id
        }
        // this.$http.post('http://my.webapi.weitianshi.com.cn/api/admin/screen/'+row.id+'/range_show' ,obj).then(r => {
        this.$http.post('https://wts.weitianshi.cn/api/admin/screen/'+row.id+'/range_show' ,obj).then(r => {
          this.pubCompanyFun()
        }).catch(err => {
          this.$message({
            message: err.message,
            type: 'warning'
          });
        })
      },
      // 获取列表
      pubCompanyFun() {
        let params = {
          data_type: this.data_type,
          organ_id: this.organ_id,
          page: this.userPage
        }
        // this.$http.get('http://my.webapi.weitianshi.com.cn/' + this.URL.adminUrl.getScreenRange, {params}).then(r => {
        this.$http.get('https://wts.weitianshi.cn/' + this.URL.adminUrl.getScreenRange, {params}).then(r => {
          this.meta = r.data.data.meta
          this.user_last_page = r.data.data.meta.last_page
          this.historyCompanyData = r.data.data.data
          this.addNum = this.historyCompanyData[0].addend
          this.mulNum = this.historyCompanyData[0].multiplier
          this.loading = false
        }).catch(err => {
        })
      },
      //计算设置
      numSet() {
        if(this.mulNum == 0){
          this.$message({
            message: "乘数不能为 0",
            type: 'warning'
          });          
        }else if(isNaN(this.addNum) || isNaN(this.mulNum)){
          this.$message({
            message: '请填写数字',
            type: 'warning'
          }); 
        }else{
          this.addNum = Number(this.addNum)
          this.mulNum = Number(this.mulNum)          
          let obj = {
            data_type: this.data_type,
            organ_id: this.organ_id,
            addend: this.addNum,
            multiplier: this.mulNum
          }
          // this.$http.post('http://my.webapi.weitianshi.com.cn/' + this.URL.screenAlgorithm, obj).then(r => {
          this.$http.post('https://wts.weitianshi.cn/' + this.URL.screenAlgorithm, obj).then(r => {
            this.$message({
              message: '数据设置成功',
              type: 'success'
            });
            this.pubCompanyFun()
          }).catch(err => {
            this.$message({
              message: err.message,
              type: 'warning'
            });
          })                      
        }
      },    
      currentPageChange(page) {
        this.page = page
        this.pubCompanyFun()
      },
    },
    created() {
      location.reload
      this.pubCompanyFun()
    }    
  }
</script>
<style lang="scss" scoped>
  #enterpriseAnalysis{
    font-size: 14px;
  }
  .e-a{
    padding-bottom: 30px;
  }
  .e-a-title{
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  .e-a-middle-wrap{
    display: flex;flex-direction: column;align-items: center;justify-content: space-around;
    width: 50%;height: 200px;
    border: 1px solid #bbb;border-radius: 20px;padding: 50px 70px;margin: 50px auto;
  }
  .e-a-middle-title{
    line-height: 40px;
  }
  .e-a-middle-btn{
    width: 200px;
  }
  .e-a-bottom{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    .e-a-innput{
      width: 150px;
    }
    .e-a-numSet{
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .e-a-numSet-btn{
        margin-left: 20px;
      }
    }
  }
  .e-a-bottom-title{
    margin-bottom: 30px;
  }
  .e-a-n-box{
    margin-top: 30px;
  }
  .pagings{
      margin-top: 20px;
      height: 40px;
  }
  .paging{
      border: 1px solid #e1e2e3;
      padding: 4px 6px;
      margin: 4px;
      color: #38f;
  }
  .paging:hover {
      border: 1px solid red;
  }
  .inputPage{
      width: 50px;
      height: 22px;
      position: relative;
      top: -1px;
  }  
</style>